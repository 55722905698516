<template>
    <panel full class="h-100">
        <panel-top-bar variant="primary-dark" size="sm" closable with-logo />
        <div class="panel-content p-0 d-flex align-items-stretch">
            <panel class="panel-front">
                <div class="panel-content p-0">
                    <div class="d-flex justify-content-between bg-primary p-3 text-white">
                        <div class="flex-shrink-1">
                            <h6 class="mb-0">Route Name</h6>
                            <p class="font-15 mb-0">Job Name</p>
                        </div>
                        <div class="flex-shrink-1 font-15">
                            Route Properties
                        </div>
                    </div>
                    <div class="route-stop-item media active">
                        <img src="/assets/images/route-location-item.svg" class="mr-3" />
                        <div class="media-body text-primary-dark">
                            <h6 class="font-weight-bold mb-1">Central Collection Location</h6>
                            <p class="mb-0 text-primary">1745 South St, Ca ñon City, CO 81212, United States</p>
                        </div>
                        <div class="media-control">
                            <a href="#">Add</a>
                        </div>
                    </div>
                    <div class="route-stop-list">
                        <route-stop-item
                            v-for="(item, index) in routeStops"
                            :key="index"
                            :name="item.name"
                            :address="item.address"
                        />
                    </div>
                    <route-search-box @add="toggleAddRoutes" />
                    <div class="bg-gray-900 d-flex align-items-center justify-content-between p-3">
                        <div class="flex-shrink-1 text-white">Add Multiple Stops</div>
                        <a href="#" class="flex-shrink-1 text-white">
                            <fa icon="plus" />
                        </a>
                    </div>
                </div>
                <div class="panel-footer panel-footer-box">
                    <button type="button" class="btn btn-default btn-block text-primary-dark" @click="goBack">Back</button>
                </div>
            </panel>
            <panel :with-header="false" v-if="addRoute">
                <div class="panel-header bg-gray-200">
                    <div class="d-flex align-items-center justify-content-between w-100">
                        <button type="button" class="text-primary close" @click="toggleAddRoutes">
                            <fa icon="arrow-left" class="mr-2" /> Back
                        </button>
                        <button type="button" class="btn btn-link p-0 text-danger font-15">
                            <fa icon="trash-alt" class="mr-2" /> Delete Stop
                        </button>
                    </div>
                    <h5 class="panel-header-title text-primary-dark">Location Properties</h5>
                    <p class="panel-header-subtitle text-primary-dark">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt</p>
                </div>
                <div class="panel-content">
                    <p class="text-label">Location Information</p>
                    <div class="form-group">
                        <label for="location_name" class="text-label font-weight-normal text-primary">Location Name</label>
                        <input type="text" id="location_name" name="location_name" class="form-control" v-model="form.location_name" />
                    </div>
                    <div class="form-group">
                        <label for="location_address" class="text-label font-weight-normal text-primary">Location Address</label>
                        <input type="text" id="location_address" name="location_address" class="form-control" v-model="form.location_address" />
                    </div>
                    <div class="form-group">
                        <label for="location_description" class="text-label font-weight-normal text-primary">Description of Location</label>
                        <textarea id="location_description" name="location_description" class="form-control" placeholder="Start typing here"  v-model="form.location_description" rows="4"></textarea>
                    </div>
                    <div class="form-group">
                        <label for="location_description" class="text-label font-weight-normal text-primary">Stop Type</label>
                        <div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="location_type_collection" name="location_type" class="custom-control-input" value="collection" v-model="form.location_type">
                                <label class="custom-control-label" for="location_type_collection">Collection Location</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="location_type_delivery" name="location_type" class="custom-control-input" value="delivery" v-model="form.location_type">
                                <label class="custom-control-label" for="location_type_delivery">Delivery Location</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel-footer panel-footer-box">
                    <button type="button" class="btn btn-primary btn-block" @click="onSave">Save</button>
                </div>
            </panel>
            <panel class="flex-grow-1 w-auto">
                <iframe width="100%" frameborder="0" style="border:0" class="flex-grow-1" src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAuzRopvvFK6QWVPYnrMfadYxChTa-glEQ&q=Colorado+Springs"></iframe>
                <div class="panel-footer">
                    <div class="ml-auto px-4">
                        <button type="button" class="btn bg-white border-primary text-primary btn-rounded px-3 mr-3">Reset</button>
                        <button type="button" class="btn bg-white border-primary text-primary btn-rounded px-3 mr-3">See Directions</button>
                        <button type="button" class="btn btn-primary btn-rounded px-3">Submit Route</button>
                    </div>
                </div>
            </panel>
        </div>
    </panel>
</template>

<script>
    import Panel from "../../components/Panel";
    import PanelTopBar from "../../components/PanelTopBar";
    import RouteSearchBox from "../../components/RouteSearchBox";
    import RouteStopItem from "../../components/RouteStopItem";

    export default {
        name: 'MultiStopC',
        components: {
            RouteStopItem,
            RouteSearchBox,
            PanelTopBar,
            Panel
        },
        data() {
            const routeStops = [];

            for (let x = 0; x < 4; x++) {
                routeStops.push({
                    name: 'Location Title',
                    address: '1705 South St, Cañon City, CO 81212, United States'
                });
            }

            return {
                routeStops: [...routeStops],
                addRoute: false,
                form: {
                    location_name: '',
                    location_address: '',
                    location_description: '',
                    location_type: 'delivery',
                }
            }
        },
        methods: {
            toggleAddRoutes() {
                this.addRoute = !this.addRoute;
            },
            onSave() {
                this.routeStops.push({
                    name: this.form.location_name,
                    address: this.form.location_address
                });

                this.resetForm();
                this.addRoute = false;
            },
            resetForm() {
                this.form = {
                    location_name: '',
                    location_address: '',
                    location_description: '',
                    location_type: 'delivery',
                };
            },
            goBack() {
                this.$router.push({ name: 'dashboard' })
            }
        }
    }
</script>
